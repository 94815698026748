import { Button, error, notify, showLoading, wordDate } from '@bctc/components';
import { Switch } from '@headlessui/react';
import {
  BanIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  IdentificationIcon,
  InformationCircleIcon,
  TrashIcon,
  UploadIcon,
  VideoCameraIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { useHookstate } from '@hookstate/core';
import { AttendanceStatus, Submission } from '@prisma/client';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PanelCard from '../../components/PanelCard';
import { logoUrl, placeholderAvatar } from '../../constants/assets';
import {
  emptyClassSession,
  emptyCohort,
  emptyCourse,
  emptyLesson,
  emptyProfile,
  emptyTeacher,
} from '../../constants/emptyModel';
import {
  getSessionBySessionId,
  markAttendance,
  updateSession,
  getAllStudentIdForEvaluations,
} from '../../services/class';
import a from '../../services/config';
import { uploadRecording } from '../../services/files';
import { giveScore } from '../../services/submission';
import { globalBreadcrumbs, globalRole, globalUser } from '../../state';
import { ClassSessionWithDetails, StudentWithProfile } from '../../types';
import Layout from '../Layout';
import UploadService from '../../services/upload';

const ClassSessionManagePage: React.FC = () => {
  const history = useHistory();
  const split = window.location.href.split('/class/')[1].split('/');
  const classId = split[0];
  const sessionId = split[1];
  const user = useHookstate(globalUser);
  const [userId, setUserId] = useState(0);

  const [studentsWithSubmissions, setStudentsWithSubmissions] = useState<
    (StudentWithProfile & { submissions: Submission[] })[]
  >([]);
  const [tab, setTab] = useState('Not Graded');
  const [evaluationTab, setEvaluationTab] = useState('Not Evaluated');
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [classSession, setClassSession] = useState<ClassSessionWithDetails>({
    ...emptyClassSession,
    attendance: [],
    comments: [],
    lesson: emptyLesson,
    submissions: [],
    cohort: {
      ...emptyCohort,
      course: emptyCourse,
      students: [],
      instructor: {
        ...emptyTeacher,
        profile: emptyProfile,
        cohorts: [],
        storeCreditTransactionsManaged: [],
        payment: [],
        announcements: [],
      },
      sessions: [],
      frequency: [],
    },
  });

  const attendanceMap = useMemo(
    () =>
      Object.fromEntries(
        classSession.attendance.map(({ studentId, status }) => [
          studentId,
          status,
        ])
      ),
    [classSession]
  );

  const notGraded = useMemo(
    () =>
      studentsWithSubmissions.filter(
        ({ submissions }) =>
          submissions.length && !submissions.find(({ scoredAt }) => scoredAt)
      ),
    [studentsWithSubmissions]
  );
  const notSubmitted = useMemo(
    () =>
      studentsWithSubmissions.filter(({ submissions }) => !submissions.length),
    [studentsWithSubmissions]
  );
  const graded = useMemo(
    () =>
      studentsWithSubmissions.filter(
        ({ submissions }) =>
          submissions.length && submissions.find(({ scoredAt }) => scoredAt)
      ),
    [studentsWithSubmissions]
  );

  let displayedSubmissions: (StudentWithProfile & {
    submissions: Submission[];
  })[] = studentsWithSubmissions;
  if (tab === 'Not Graded') displayedSubmissions = notGraded;
  if (tab === 'Not Submitted') displayedSubmissions = notSubmitted;
  if (tab === 'Graded') displayedSubmissions = graded;

  const giveAllStudent100 = async () => {
    showLoading({ description: 'Grading students...' });
    const results = await Promise.all(
      notGraded.map(({ submissions }) =>
        giveScore(submissions[0].id, 100).then((res) => res.data)
      )
    );
    if (results.find((result) => !result)) return;
    notify({ description: 'Successfully gave all students 100s' });
    fetchSession();
  };

  const fetchSession = async () => {
    const { data: result } = await getSessionBySessionId(sessionId);
    if (!result) return;
    setClassSession(result);
    setStudentsWithSubmissions(
      result.cohort.students.map(({ student }) => ({
        ...student,
        submissions: result.submissions
          .filter(
            (submission: Submission) => submission.submitterId === student.id
          )
          .sort(
            (a: Submission, b: Submission) =>
              new Date(b.submittedAt).getTime() -
              new Date(a.submittedAt).getTime()
          ),
      }))
    );
    setLoading(false);
  };
  const isInfinityClass = useMemo(() => {
    return classSession.cohort.endDate == null && classSession.id;
  }, [classSession]);

  const handleUploadRecording = async (file: File) => {
    setUploadProgress(0);
    const url = await uploadRecording(file, {
      filename: `${classSession.cohort.course.title
        .split(' ')
        .join('-')
        .toLowerCase()}_${classSession.cohort.cohortNumber}_${sessionId}`,
      onUploadProgress: (progressEvent) =>
        setUploadProgress(
          +((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
        ),
    });
    if (!url) {
      setUploadProgress(-1);
      return;
    }

    const { data: updateResult } = await updateSession(classSession.id, {
      recording: url,
    });
    if (!updateResult) {
      setUploadProgress(-1);
      return;
    }

    notify({ description: 'Recording has been uploaded' });
    fetchSession();
  };

  const handleAttendance = async (
    studentId: string,
    status: AttendanceStatus
  ) => {
    if (!user.value) return;
    const { data: result } = await markAttendance(
      classSession.id,
      studentId,
      status,
      userId
    );
    if (!result) return;
    fetchSession();
  };

  const removeRecording = async () => {
    const { data: result } = await updateSession(classSession.id, {
      recording: null,
    });
    if (!result) return;
    notify({ description: 'Recording has been removed' });
    fetchSession();
  };

  const sendReminderEmailByStudent = async (studentId: string) => {
    await a.post(`/emails/submissionReminder/${studentId}`, {
      sessionId: classSession.id,
    });
    notify({ description: 'A reminder email has been sent' });
  };

  const toggleClassSessionVisibility = async () => {
    const { data: result } = await updateSession(classSession.id, {
      disabled: !classSession.disabled,
    });
    if (!result) return;
    notify({
      description: `Class session is now ${
        classSession.disabled ? 'disabled' : 'visible'
      }`,
    });
    fetchSession();
  };

  const studentIsGraded = (
    student: StudentWithProfile & {
      submissions: Submission[];
    }
  ) => student.submissions.find((submission) => submission.scoredAt);

  const forceSubmit = async (studentId: string) => {
    const result = await UploadService.handleUpsertSubmission({
      attachment: null,
      content: 'This submission was force submitted by the instructor',
      link: logoUrl,
      student: studentId,
      type: 'HOMEWORK',
      classSession: classSession.id,
      codingFile: null,
      score: 100,
    });

    if (result) {
      notify({
        description: 'Homework force submitted successfully',
      });
      fetchSession();
    } else {
      error('Something went wrong while submitting');
      return;
    }
  };

  useEffect(() => {
    globalBreadcrumbs.set([
      { title: 'Home', href: '/class' },
      {
        title: loading
          ? 'Loading...'
          : `${classSession.cohort.course.title} Cohort ${classSession.cohort.cohortNumber}`,
        href: loading ? '#' : `/class/${classId}`,
      },
      {
        title: loading ? 'Loading...' : `Session`,
        href: loading ? '#' : `/class/${classId}/${sessionId}`,
      },
      {
        title: `Manage`,
        href: window.location.pathname,
        isCurrent: true,
      },
    ]);
  }, [loading]);

  useEffect(() => {
    fetchSession();
    fetchAllStudentIdForEvaluation(+classId, sessionId);
  }, []);

  useEffect(() => {
    setUserId(user.value.profile.teacherId);
  }, [user]);

  const [allEvaluations, setAllEvaluations] = useState({
    evaluated: [],
    notEvaluated: [],
  });
  const fetchAllStudentIdForEvaluation = async (
    cohortID: number,
    sessionId: string
  ) => {
    const { data } = await getAllStudentIdForEvaluations(cohortID, sessionId);
    setAllEvaluations(data);
    return;
  };

  console.log(classSession?.cohort?.students);

  const whenFilterButtonsClicked =
    'z-10 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ring-blue-500 border-blue-500 ring-1 outline-none';

  return (
    <Layout bg>
      <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
        <div className='grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8'>
          <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
            <PanelCard
              padding={false}
              headerText='Submissions'
              divider
              headerRight={
                <span className='relative z-0 inline-flex rounded-md shadow-sm'>
                  <button
                    onClick={() => setTab('All')}
                    type='button'
                    className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      tab === 'All'
                        ? 'focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ring-blue-500 border-blue-500 ring-1 outline-none'
                        : null
                    } z-10`}
                  >
                    All
                  </button>
                  <button
                    onClick={() => setTab('Not Graded')}
                    type='button'
                    className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      tab === 'Not Graded' ? whenFilterButtonsClicked : null
                    }`}
                  >
                    Not Graded
                  </button>
                  <button
                    onClick={() => setTab('Not Submitted')}
                    type='button'
                    className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      tab === 'Not Submitted' ? whenFilterButtonsClicked : null
                    }`}
                  >
                    Not Submitted
                  </button>
                  <button
                    onClick={() => setTab('Graded')}
                    type='button'
                    className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      tab === 'Graded'
                        ? 'focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ring-blue-500 border-blue-500 ring-1 outline-none'
                        : null
                    }`}
                  >
                    Graded
                  </button>
                </span>
              }
            >
              {tab === 'Not Graded' && notGraded.length > 0 ? (
                <button
                  onClick={giveAllStudent100}
                  type='button'
                  className='mt-2 ml-4 mb-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none'
                >
                  Give all students 100 for this submission
                  <CheckCircleIcon
                    className='ml-2 -mr-0.5 h-4 w-4'
                    aria-hidden='true'
                  />
                </button>
              ) : null}

              <ul role='list' className='divide-y divide-gray-200'>
                {loading ? (
                  <p className='w-full my-10 text-center text-gray-400'>
                    Loading...
                  </p>
                ) : displayedSubmissions.length ? (
                  displayedSubmissions.map((v, i) => (
                    <li
                      key={i}
                      className={`${
                        v.submissions.length
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      }`}
                    >
                      <a
                        onClick={() =>
                          v.submissions.length
                            ? history.push(
                                `/class/${classId}/${sessionId}/submission/${v.id}`
                              )
                            : null
                        }
                        className='block hover:bg-gray-50'
                      >
                        <div className='flex items-center px-4 py-4 sm:px-6'>
                          <div className='flex items-center flex-1 min-w-0'>
                            <div className='flex-shrink-0'>
                              <img
                                className='w-12 h-12 rounded-full'
                                src={v.profile?.avatar || placeholderAvatar}
                                alt=''
                              />
                            </div>
                            <div className='min-w-0 px-4 flex justify-between w-full'>
                              <div>
                                <p className='text-sm font-medium text-blue-600 truncate'>
                                  {v.profile?.firstName} {v.profile?.lastName}
                                </p>
                                <p className='flex items-center mt-2 text-sm text-gray-500'>
                                  <IdentificationIcon
                                    className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                  <span className='truncate'>{v.id}</span>
                                </p>
                              </div>
                              <div className='justify-end hidden md:flex'>
                                <div className='flex justify-center text-right'>
                                  <p className='flex justify-end items-center text-sm mr-8 text-gray-500'>
                                    {v.submissions[0] ? (
                                      <>
                                        Submitted on{' '}
                                        {wordDate(v.submissions[0].submittedAt)}
                                      </>
                                    ) : null}
                                  </p>
                                  <p
                                    className={classNames(
                                      'flex justify-end items-center text-sm',
                                      {
                                        'text-gray-500': !studentIsGraded(v),
                                        'text-green-500': studentIsGraded(v),
                                      }
                                    )}
                                  >
                                    {v.submissions.length
                                      ? studentIsGraded(v)
                                        ? `Graded ${
                                            v.submissions.find(
                                              ({ scoredAt }) => scoredAt
                                            ).score
                                          }%`
                                        : 'Not Graded'
                                      : ''}
                                    {v.submissions.length ? (
                                      <CheckCircleIcon
                                        className={classNames(
                                          'flex-shrink-0 ml-1.5 h-5 w-5',
                                          {
                                            'text-green-400':
                                              studentIsGraded(v),
                                            'text-gray-400':
                                              !studentIsGraded(v),
                                          }
                                        )}
                                        aria-hidden='true'
                                      />
                                    ) : (
                                      <div className='space-x-2 flex'>
                                        <Button
                                          onClick={() => forceSubmit(v.id)}
                                          size='sm'
                                          buttonVariant='info'
                                        >
                                          Pardon
                                        </Button>
                                        <Button
                                          size='sm'
                                          color='blue'
                                          onClick={() => {
                                            sendReminderEmailByStudent(v.id);
                                          }}
                                        >
                                          Send Reminder
                                        </Button>
                                      </div>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                  <p className='w-full my-10 text-center text-gray-400'>
                    No Data
                  </p>
                )}
              </ul>
            </PanelCard>
            <PanelCard
              padding={false}
              headerText='Evaluations'
              divider
              headerRight={
                <span className='relative z-0 inline-flex rounded-md shadow-sm'>
                  <button
                    onClick={() => setEvaluationTab('All')}
                    type='button'
                    className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      evaluationTab === 'All' ? whenFilterButtonsClicked : null
                    } z-10`}
                  >
                    All
                  </button>
                  <button
                    onClick={() => setEvaluationTab('Not Evaluated')}
                    type='button'
                    className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      evaluationTab === 'Not Evaluated'
                        ? whenFilterButtonsClicked
                        : null
                    }`}
                  >
                    Not Evaluated
                  </button>
                  <button
                    onClick={() => setEvaluationTab('Evaluated')}
                    type='button'
                    className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none ${
                      evaluationTab === 'Evaluated'
                        ? whenFilterButtonsClicked
                        : null
                    }`}
                  >
                    Evaluated
                  </button>
                </span>
              }
            >
              <ul role='list' className='divide-y divide-gray-200'>
                {loading ? (
                  <p className='w-full my-10 text-center text-gray-400'>
                    Loading...
                  </p>
                ) : allEvaluations ? (
                  (() => {
                    const evaluatedStudents = allEvaluations.evaluated;
                    const notEvaluatedStudents = allEvaluations.notEvaluated;
                    let displayStudents = [];

                    switch (evaluationTab) {
                      case 'All':
                        displayStudents = [
                          ...evaluatedStudents,
                          ...notEvaluatedStudents,
                        ];
                        break;
                      case 'Evaluated':
                        displayStudents = evaluatedStudents;
                        break;
                      case 'Not Evaluated':
                        displayStudents = notEvaluatedStudents;
                        break;
                      default:
                        // Handle any unexpected evaluationTab value
                        displayStudents = [];
                        break;
                    }

                    return displayStudents.map((student) => {
                      const isNotEvaluated =
                        notEvaluatedStudents.includes(student);
                      return (
                        <li key={student.id} className='cursor-pointer'>
                          <a
                            onClick={() => {
                              if (isNotEvaluated) {
                                history.push(`/student/${student.id}`);
                              }
                            }}
                            className='block hover:bg-gray-50'
                          >
                            <div className='flex items-center px-4 py-4 sm:px-6'>
                              <div className='flex items-center flex-1 min-w-0'>
                                <div className='flex-shrink-0'>
                                  <img
                                    className='w-12 h-12 rounded-full'
                                    src={
                                      student.profile?.avatar ||
                                      placeholderAvatar
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className='min-w-0 px-4 flex justify-between w-full'>
                                  <div>
                                    <p className='text-sm font-medium text-blue-600 truncate'>
                                      {student.profile?.firstName}{' '}
                                      {student.profile?.lastName}
                                    </p>
                                    <p className='flex items-center mt-2 text-sm text-gray-500'>
                                      <IdentificationIcon
                                        className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                        aria-hidden='true'
                                      />
                                      <span className='truncate'>
                                        {student.id}
                                      </span>
                                    </p>
                                  </div>
                                  <div className='justify-end hidden md:flex'>
                                    <p
                                      className={classNames(
                                        'flex justify-end items-center text-sm',
                                        {
                                          'text-green-500':
                                            evaluatedStudents.includes(student),
                                          'text-gray-500':
                                            notEvaluatedStudents.includes(
                                              student
                                            ),
                                        }
                                      )}
                                    >
                                      {/* {isNotEvaluated
                                        ? 'Not Evaluated'
                                        : 'Evaluated'} */}
                                      {/* {isNotEvaluated ? (
                                        <XCircleIcon
                                          className='flex-shrink-0 ml-1.5 h-5 w-5 text-red-400'
                                          aria-hidden='true'
                                        /> */}

                                      {!isNotEvaluated && (
                                        <>
                                          <CheckCircleIcon
                                            className='flex-shrink-0 m-1.5 h-5 w-5 text-green-400'
                                            aria-hidden='true'
                                          />
                                          <p>Evaluated</p>
                                        </>
                                      )}
                                    </p>
                                    {isNotEvaluated && (
                                      <div className='space-x-2 flex items-center ml-3'>
                                        <Button
                                          size='sm'
                                          color='blue'
                                          className='h-fit'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            history.push(
                                              `/evaluation/${classSession.id}/${userId}/${student.id}/teacher`
                                            );
                                          }}
                                        >
                                          Evaluate
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    });
                  })()
                ) : (
                  <></>
                )}
              </ul>
            </PanelCard>
          </div>
          <div className='grid grid-cols-1 gap-4 lg:col-span-1'>
            <button
              type='button'
              onClick={() => history.push(`/class/${classId}/${sessionId}`)}
              className='bg-THEME-400 text-white px-4 py-2 w-full rounded-lg hover:bg-blue-500'
            >
              See all materials
            </button>
            {(
              <PanelCard headerText='Recording Video' divider padding={false}>
                <div>
                  <div>
                    {loading ? (
                      <p className='py-4 font-medium text-center text-gray-400 text-md'>
                        Loading...
                      </p>
                    ) : classSession.recording ? (
                      <div className='flex items-center justify-between px-6 py-4'>
                        <div className='flex items-center justify-center'>
                          <VideoCameraIcon className='mr-2 text-blue-600 h-7 w-7' />
                          <p className='text-gray-600'>Recording uploaded</p>
                        </div>
                        <button type='button' onClick={() => removeRecording()}>
                          <TrashIcon className='w-6 h-6 text-red-400 hover:text-red-600' />
                        </button>
                      </div>
                    ) : (
                      <div className='p-6'>
                        <div className='flex justify-center max-w-lg py-6 mx-auto border-2 border-gray-300 border-dashed rounded-md'>
                          <div className='flex-row items-center space-y-1 text-center'>
                            <UploadIcon className='w-12 h-12 mx-auto text-gray-400' />
                            {uploadProgress !== -1 ? (
                              <div className='flex-row items-center justify-center text-gray-500'>
                                <p>Uploading...</p>
                                {uploadProgress === 100 ? (
                                  <p>Almost done~</p>
                                ) : (
                                  <p>{uploadProgress}%</p>
                                )}
                              </div>
                            ) : (
                              <div className='flex justify-center text-sm text-center text-gray-600'>
                                <label
                                  htmlFor='file-upload'
                                  className='relative font-medium text-gray-400 bg-white rounded-md cursor-pointer hover:text-gray-500 focus-within:outline-none'
                                >
                                  <span>Upload Video</span>
                                  <input
                                    id='file-upload'
                                    name='file-upload'
                                    type='file'
                                    accept='video/*'
                                    onChange={(e) =>
                                      e.target.files.length &&
                                      handleUploadRecording(e.target.files[0])
                                    }
                                    className='sr-only'
                                  />
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </PanelCard>
            )}

            {!isInfinityClass && (
              <PanelCard headerText='Change Visibility' padding={false}>
                <div className='flex items-center justify-between p-6 pt-0'>
                  <p>
                    Disable class visibility:
                    {loading ? (
                      <span className='text-gray-500'> Loading...</span>
                    ) : null}
                  </p>
                  {loading ? null : (
                    <Switch
                      checked={classSession.disabled}
                      onChange={toggleClassSessionVisibility}
                      className={classNames(
                        classSession.disabled ? 'bg-blue-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                      )}
                    >
                      <span className='sr-only'>Use setting</span>
                      <span
                        aria-hidden='true'
                        className={classNames(
                          classSession.disabled
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                  )}
                </div>
              </PanelCard>
            )}

            <PanelCard headerText='Attendance' divider>
              <ul role='list' className='p-0'>
                {loading ? (
                  <p className='font-medium text-center text-gray-400 truncate text-md'>
                    Loading...
                  </p>
                ) : (
                  classSession?.cohort?.students
                    .filter((student) => student.isActivate)
                    .map(({ student }) => student)
                    .map((student, i) => (
                      <li
                        key={student.id}
                        className={classNames(
                          'flex items-center justify-between',
                          {
                            'pb-6':
                              i !== classSession.cohort.students.length - 1,
                          }
                        )}
                      >
                        <div className='flex items-center flex-1 min-w-0 space-x-3'>
                          <div className='flex-shrink-0'>
                            <img
                              className='w-10 h-10 rounded-full'
                              src={student.profile.avatar || placeholderAvatar}
                              alt=''
                            />
                          </div>
                          <div className='flex-1 min-w-0'>
                            <p className='text-sm font-medium text-gray-900 truncate'>
                              {`${student.profile.firstName} ${
                                student.profile.lastName || ''
                              }`}
                            </p>
                            <p className='text-sm font-medium text-gray-500 truncate'>
                              {student.id}
                            </p>
                          </div>
                        </div>
                        <div className='flex-shrink-0 space-x-2'>
                          <button
                            title='Present'
                            disabled={attendanceMap[student.id] === 'PRESENT'}
                            onClick={() =>
                              handleAttendance(student.id, 'PRESENT')
                            }
                            type='button'
                            className={`inline-flex items-center py-1 px-1 border border-transparent rounded-full focus:outline-none ${
                              attendanceMap[student.id] === 'PRESENT'
                                ? 'bg-green-500'
                                : 'bg-gray-300 hover:bg-gray-400'
                            }`}
                          >
                            <span className='text-sm font-medium text-white-100'>
                              <CheckIcon
                                className='w-5 h-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          </button>
                          <button
                            title='Late'
                            disabled={attendanceMap[student.id] === 'LATE'}
                            onClick={() => handleAttendance(student.id, 'LATE')}
                            type='button'
                            className={`inline-flex items-center py-1 px-1 border border-transparent rounded-full focus:outline-none ${
                              attendanceMap[student.id] === 'LATE'
                                ? 'bg-yellow-500'
                                : 'bg-gray-300 hover:bg-gray-400'
                            }`}
                          >
                            <span className='text-sm font-medium text-white-100'>
                              <ClockIcon
                                className='w-5 h-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          </button>
                          <button
                            title='Absent'
                            disabled={attendanceMap[student.id] === 'ABSENT'}
                            onClick={() =>
                              handleAttendance(student.id, 'ABSENT')
                            }
                            type='button'
                            className={`inline-flex items-center py-1 px-1 border border-transparent rounded-full focus:outline-none ${
                              attendanceMap[student.id] === 'ABSENT'
                                ? 'bg-red-500'
                                : 'bg-gray-300 hover:bg-gray-400'
                            }`}
                          >
                            <span className='text-sm font-medium text-white-100'>
                              <BanIcon
                                className='w-5 h-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          </button>
                          <button
                            title='Excused'
                            disabled={attendanceMap[student.id] === 'EXCUSED'}
                            onClick={() =>
                              handleAttendance(student.id, 'EXCUSED')
                            }
                            type='button'
                            className={`inline-flex items-center py-1 px-1 border border-transparent rounded-full focus:outline-none ${
                              attendanceMap[student.id] === 'EXCUSED'
                                ? 'bg-blue-500'
                                : 'bg-gray-300 hover:bg-gray-400'
                            }`}
                          >
                            <span className='text-sm font-medium text-white-100'>
                              <InformationCircleIcon
                                className='w-5 h-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          </button>
                        </div>
                      </li>
                    ))
                )}
              </ul>
            </PanelCard>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClassSessionManagePage;
