import React, { useEffect } from 'react';
import { logoUrl } from '../../constants/assets';
import AuthService from '../../services/auth';
import {
  globalAuthToken,
  globalRole,
  globalThirdPartyAccessToken,
  globalUser,
} from '../../state';
import { Persistence } from '@hookstate/persistence';
import { useHistory } from 'react-router';
import { useState } from '@hookstate/core';
import { clearThirdPartyToken } from '../../services/config';
import FullscreenLoading from '../../components/FullscreenLoading';
import UserService from '../../services/user';
import { Link } from 'react-router-dom';
import { User } from '../../types';
import Modal from '../../components/Modal';
import { Input, error } from '@bctc/components';
import { GUEST_STUDENT_ID } from '../../constants';

const LoginPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const thirdPartyToken = useState(globalThirdPartyAccessToken);
  useEffect(() => {
    thirdPartyToken.set(null);
    clearThirdPartyToken();
  }, []);
  const [loginStep, setLoginStep] = React.useState<
    'USERNAME' | 'NEW_USER' | 'PASSWORD'
  >('USERNAME');
  const [form, setForm] = React.useState({
    username: '',
    password: '',
    confirmPassword: '',
  });
  const history = useHistory();
  const showSSOModal = useState(false);
  const showCollectModal = useState(false);

  const handleSignIn = async () => {
    if (loginStep === 'USERNAME') {
      if (form.username === GUEST_STUDENT_ID) {
        error('You do not have access to this account');
        return;
      }
      if (!form.username) {
        error('Please enter your email address or student ID');
        return;
      }
      setLoading(true);
      const { data } = await AuthService.checkExist(
        form.username.toLowerCase()
      );
      setLoading(false);
      if (data.error) {
        error(data.error);
        return;
      }
      if (data.hasPassword) {
        setLoginStep('PASSWORD');
      } else {
        setLoginStep('NEW_USER');
      }
    } else {
      if (loginStep === 'NEW_USER' && form.password !== form.confirmPassword) {
        error('Passwords do not match');
        return;
      }
      if (
        loginStep === 'NEW_USER' &&
        (form.password.length !== 4 || /^\d+$/.test(form.password) == false)
      ) {
        error('Password must be 4 numbers');
        return;
      }
      if (loginStep === 'NEW_USER') {
        setLoading(true);
        const { data } = await AuthService.bindPassword({
          username: form.username.toLowerCase(),
          password: form.password,
        });
        setLoading(false);
        if (!data) {
          error('Failed to reset password');
          return;
        }
      }
      if (!form.password) {
        error('Please enter your password');
        return;
      }
      setLoading(true);
      const { data: authResponse } = await AuthService.signIn({
        username: form.username.toLowerCase(),
        password: form.password,
      });

      if (authResponse.error) {
        setLoading(false);
        error(authResponse.error);
        return;
      }

      globalAuthToken.set(authResponse);
      globalAuthToken.attach(Persistence('state.authToken'));

      const { data: userResponse } = await AuthService.getUserInfoByToken(
        authResponse
      );

      if (!userResponse) {
        setLoading(false);
        return;
      }

      const { role, uid } = userResponse;

      let response: User;
      if (role === 'student') {
        response = await UserService.fetchStudentByStudentId(uid).then(
          (res) => res.data
        );
      } else {
        response = await UserService.fetchTeacherById(uid).then(
          (res) => res.data
        );
      }
      setLoading(false);
      if (response) {
        const previousPage = localStorage.getItem('previousPage');
        globalRole.set(role);
        globalUser.set(response);
        globalRole.attach(Persistence('state.role'));
        globalUser.attach(Persistence('state.user'));
        localStorage.removeItem('previousPage');
        history.push(previousPage || '/');
      } else {
        error(`Failed to fetch ${role} data`);
        return;
      }
    }
  };

  const handleGuestSignIn = async () => {
    const { data: authResponse } = await AuthService.guestSignIn({
      authCode: '0331',
    });

    if (!authResponse) {
      error(`Failed to sign in as guest`);
      return;
    }

    globalAuthToken.set(authResponse);
    globalAuthToken.attach(Persistence('state.authToken'));

    const { data: userResponse } = await AuthService.getUserInfoByToken(
      authResponse
    );

    if (!userResponse) return;

    const { role, uid } = userResponse;

    let response: User;
    if (role === 'student') {
      response = await UserService.fetchStudentByStudentId(uid).then(
        (res) => res.data
      );
    } else {
      response = await UserService.fetchTeacherById(uid).then(
        (res) => res.data
      );
    }

    if (response) {
      globalRole.set(role);
      globalUser.set(response);
      globalRole.attach(Persistence('state.role'));
      globalUser.attach(Persistence('state.user'));
      localStorage.removeItem('previousPage');
      history.push('/files');
    } else {
      error(`Failed to fetch ${role} data`);
      return;
    }
  };

  return (
    <div className='flex min-h-screen bg-white'>
      <div className='flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
        <div className='w-full max-w-sm mx-auto lg:w-96'>
          <div>
            <img className='w-auto h-28' src={logoUrl} alt='Workflow' />
          </div>

          <div className='mt-8'>
            <div className='mt-6'>
              <form
                action='#'
                method='POST'
                className='space-y-3'
                onSubmit={(e) => e.preventDefault()}
              >
                {loginStep === 'USERNAME' && (
                  <Input
                    onChange={(e) => setForm({ ...form, username: e })}
                    value={form.username}
                    label='Email Address or Student ID'
                    placeholder='john@gmail.com'
                    onEnter={handleSignIn}
                    type='email'
                  />
                )}

                {loginStep === 'NEW_USER' && (
                  <div>
                    <Input
                      onChange={(e) => setForm({ ...form, password: e })}
                      value={form.password}
                      label='Password'
                      placeholder='Please enter the password'
                      type='password'
                      onEnter={handleSignIn}
                    />
                    <Input
                      onChange={(e) => setForm({ ...form, confirmPassword: e })}
                      value={form.confirmPassword}
                      label='Confirm Password'
                      placeholder='Please enter the password'
                      type='password'
                      onEnter={handleSignIn}
                    />
                  </div>
                )}

                {loginStep === 'PASSWORD' && (
                  <Input
                    onChange={(e) => setForm({ ...form, password: e })}
                    value={form.password}
                    label='Password'
                    placeholder='Please enter the password'
                    type='password'
                    onEnter={handleSignIn}
                  />
                )}

                <div className='flex items-center justify-between'>
                  <div className='text-sm'>
                    <Link
                      to='/forgotpassword'
                      className='font-medium text-blue-600 hover:text-blue-500'
                    >
                      Forgot Password (Email Login)
                    </Link>
                  </div>
                  <div
                    onClick={() => handleGuestSignIn()}
                    className='text-blue-400 text-sm hover:text-blue-600 hover:cursor-pointer'
                  >
                    I am a Guest
                  </div>
                  {loginStep !== 'USERNAME' && (
                    <div className='text-sm'>
                      <div
                        onClick={() => setLoginStep('USERNAME')}
                        className='font-medium text-blue-600 hover:text-blue-500 cursor-pointer'
                      >
                        back
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex justify-between py-2 gap-4'>
                  <button
                    disabled={loading}
                    type='button'
                    onClick={handleSignIn}
                    className={`${
                      loading ? 'animate-bounce bg-slate-400' : ''
                    } flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    {loading
                      ? 'Loading'
                      : loginStep == 'USERNAME'
                      ? 'Next'
                      : 'Sign in'}
                  </button>
                </div>
              </form>
              {/* {loginSelectionState.value === '_' ? (
                <div className='mt-2 text-sm'>
                  {!auth.isLoading ? (
                    <div className='flex items-center'>
                      <a
                        onClick={() => showSSOModal.set(true)}
                        className='font-medium text-blue-600 hover:text-blue-500 cursor-pointer'
                      >
                        What is SSO?
                      </a>
                    </div>
                  ) : (
                    <span>SSO Loading...</span>
                  )}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showSSOModal.get()}
        close={() => showSSOModal.set(false)}
        width='sm:max-w-2xl'
      >
        <p className='text-2xl font-bold text-gray-900'>What is SSO?</p>
        <p className='break-words text-l text-gray-900 mt-4'>
          Single Sign-On or SSO is a service that allows you to sign in to
          multiple websites using a single set of credentials. Even allow you to
          sign in to websites using your social media accounts.
        </p>
        <p className='break-words text-l text-gray-900 mt-4'>
          The Future Sphere is developing many new and exciting products and
          services. We are using SSO to make it easier for you to sign in to all
          of our products and services.
        </p>
        <p className='break-words text-l text-gray-900 mt-4'>
          Don&apos;t worry, if you don&apos;t want to use SSO, you can still
          sign in to student portal with your email address and password. But
          you&apos;ll miss out on new products and services that we are
          developing which <i>requires</i> SSO to sign in.
        </p>
        <p className='text-2xl font-bold text-gray-900'>How to migrate?</p>
        <p className='break-words text-l text-gray-900 mt-4'>
          If you want to migrate your account to SSO, login to portal and
          navigate to <b>Settings -&gt; Your Profile -&gt; Migrate to SSO</b>.
          Then you will sign up SSO which will automatically guide you to
          migrate your account. Once your account is migrated, you <i>only</i>{' '}
          can sign in to student portal using SSO.
        </p>
        <p className='break-words text-l text-gray-900 mt-4'>
          If you have any questions, please contact Future Sphere IT Helpdesk.
        </p>
      </Modal>
      <Modal
        open={showCollectModal.get()}
        close={() => showCollectModal.set(false)}
        width='sm:max-w-2xl'
      >
        <p className='text-2xl font-bold text-gray-900'>What is Collected?</p>
        <p className='break-words text-l text-gray-900 mt-4'>
          The Future Sphere is always looking for ways to improve our products
          and services. We are using Google Analytics, DataDog Log Management,
          and Real User Monitoring to collect usage data. This data is used to
          improve our products and services.
        </p>
        <p className='break-words text-l text-gray-900 mt-4'>
          The Future Sphere is committed to protecting your privacy. We will not
          share your personal information with any third parties. By agreeing to
          allow collection of usage data, your website session interaction will
          be collected.
        </p>
        <p className='break-words text-l text-gray-900 mt-4'>
          Here is list of what we collect:
        </p>
        <ul className='list-disc list-inside text-l text-gray-900 mt-4'>
          <li>Browser Information</li>
          <li>Device Information</li>
          <li>IP Address</li>
          <li>Page Visits</li>
          <li>Clicks (Only collected in interaction mode)</li>
          <li>Scrolls (Only collected in interaction mode)</li>
          <li>Errors</li>
          <li>Performance (Only collected in interaction mode)</li>
        </ul>
        <p className='break-words text-l text-gray-900 mt-4'>
          If you have any questions, please contact Future Sphere IT Helpdesk.
        </p>
      </Modal>
      <div className='relative flex-1 hidden w-0 lg:block'>
        <FullscreenLoading />
      </div>
    </div>
  );
};

export default LoginPage;
