import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  XCircleIcon,
  PlusIcon,
} from '@heroicons/react/outline';
import { useHookstate } from '@hookstate/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClassService, {
  fetchSingleEvaluatedSessionStatusForStudent,
} from '../../services/class';
import { globalBreadcrumbs, globalRole, globalUser } from '../../state';
import {
  CohortWithDetails,
  IStudent,
  IStudentEnrollment,
  ITeacher,
} from '../../types';
import Layout from '../Layout';
import { Persistence } from '@hookstate/persistence';
import { placeholderAvatar } from '../../constants/assets';
import Loading from '../../components/Loading';
import {
  UserInfoPill,
  PanelCard,
  Modal,
  SingleDropdown,
  falseyToEmpty,
  error,
} from '@bctc/components';
import ClassDetailPanel from '../../components/ClassDetailPanel';
import Empty from '../../components/Empty';
import {
  emptyCohort,
  emptyCourse,
  emptyProfile,
  emptyTeacher,
} from '../../constants/emptyModel';
import { Lesson } from '@prisma/client';

const ClassDetailPage: React.FC = () => {
  const EVALUATION_SESSION_INTERVAL = 4;
  const cohortId = +window.location.pathname.split('/class/')[1];

  const role = useHookstate(globalRole);
  const user = useHookstate(globalUser);
  role.attach(Persistence('state.role'));
  const isTeacher = role.value === 'teacher';
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [selectedSession, setSelectedSession] = useState<Lesson | null>(null);
  const [cohort, setCohort] = useState<CohortWithDetails>({
    ...emptyCohort,
    course: emptyCourse,
    students: [],
    instructor: {
      ...emptyTeacher,
      profile: { ...emptyProfile },
      cohorts: [],
      announcements: [],
      payment: [],
      storeCreditTransactionsManaged: [],
    },
    frequency: [],
    sessions: [],
    grades: {},
  });
  const [isAddingNewSession, setIsAddingNewSession] = useState(false);
  const [wkstCode, setWkstCode] = useState('');

  const isInfinityClass = useMemo(() => {
    return cohort.endDate == null && cohort.id;
  }, [cohort]);

  const fetchCohort = async () => {
    if (cohortId < 1 || isNaN(cohortId)) {
      return history.push('/class');
    }
    setLoading(true);
    const { data: result } = await ClassService.getClassInfoByClassId(cohortId);
    if (!result) return;
    if (
      !isTeacher &&
      !result.students.find((student) => student.studentId == user.value.id)
    ) {
      return history.push('/class');
    }
    let classSessions = [];
    if (!isInfinityClass) {
      classSessions = result.sessions.sort(
        (a, b) => a.lesson.lessonIndex - b.lesson.lessonIndex
      );
    } else {
      classSessions = result.sessions;
    }
    const { data: accessCode } = await ClassService.getWorkstationAccessCode();
    setWkstCode(accessCode.code);
    setCohort({ ...result, sessions: classSessions });
    setLoading(false);
  };

  globalBreadcrumbs.set([
    { title: 'Home', href: '/class' },
    {
      title: loading
        ? 'Loading...'
        : `${cohort.course.title} Cohort ${cohort.cohortNumber}`,
      href: `/class/${cohortId}`,
      isCurrent: true,
    },
  ]);

  const fetchLessons = async () => {
    if (!cohort.course.id) return;
    const { data: lessons } = await ClassService.fetchLessonByCourseId(
      cohort.course.id
    );
    if (!lessons) return;
    setLessons(lessons);
  };

  useEffect(() => {
    fetchCohort();
  }, [isInfinityClass]);

  // same logic used in class detail panel
  const completedSessions = useMemo(() => {
    return cohort.sessions.filter((session) => session.attendance.length);
  }, [cohort.sessions]);

  const totalSessions = cohort.sessions.length;

  const { evaluationIntervalStartIndex, evaluationIntervalStartIds } =
    useMemo(() => {
      const startIndex = [];
      let counter = 1;
      for (let i = 1; i <= totalSessions; i += 1) {
        if (
          counter % EVALUATION_SESSION_INTERVAL === 1 &&
          counter + EVALUATION_SESSION_INTERVAL <= totalSessions
        ) {
          startIndex.push(i - 1);
        }
        counter += 1;
      }
      const startIds = startIndex.map((index) => cohort.sessions[index].id);
      return {
        evaluationIntervalStartIndex: startIndex,
        evaluationIntervalStartIds: startIds,
      };
    }, [cohort.sessions, totalSessions]);

  const [evaluatedSessions, setEvaluatedSessions] = useState<
    Record<number, boolean>
  >({});
  const [studentId, setStudentId] = useState('');

  const teacherId = String(cohort.instructorId);

  const fetchEvaluatedSessions = useCallback(
    async (sessionIndex: number, studentId: string, teacherId: string) => {
      try {
        const classSessionId = cohort.sessions[sessionIndex].id;
        const { data: hasEvaluated } =
          await fetchSingleEvaluatedSessionStatusForStudent(
            classSessionId,
            studentId,
            teacherId
          );

        setEvaluatedSessions((prevEvaluatedSessions) => ({
          ...prevEvaluatedSessions,
          [sessionIndex]: hasEvaluated.hasEvaluated,
        }));
      } catch (error) {
        console.error('Error fetching evaluated session status:', error);
      }
    },
    [fetchSingleEvaluatedSessionStatusForStudent, cohort.sessions]
  );

  useEffect(() => {
    setStudentId(String(user.value.id));
  }, [user]);

  useEffect(() => {
    const fetchAllEvaluations = async () => {
      if (studentId && teacherId) {
        for (
          let i = 0;
          i < cohort.sessions.length;
          i += EVALUATION_SESSION_INTERVAL
        ) {
          if (i + EVALUATION_SESSION_INTERVAL <= cohort.sessions.length) {
            await fetchEvaluatedSessions(i, studentId, teacherId);
          }
        }
      }
    };

    fetchAllEvaluations();
  }, [cohort.sessions, studentId, teacherId, fetchEvaluatedSessions]);
  useEffect(() => {
    if (isInfinityClass) {
      fetchLessons();
    }
  }, [cohort.course]);

  useEffect(() => {
    if (!isTeacher && !loading && cohort.students.length) {
      const student = cohort.students.find(
        (student) => student.studentId === user.value.id
      );
      if (!student || !student.isActivate) {
        history.push('/class');
        error('You are not enrolled in this class');
      }
    }
  }, [isTeacher, loading, cohort.students]);

  const addNewSessionToCohort = async () => {
    if (!selectedSession) return;
    const { data } = await ClassService.addSessionToCohort({
      cohortId: cohort.id,
      lessonId: selectedSession.id,
    });

    if (data) {
      setCohort((prev) => ({
        ...prev,
        sessions: [...prev.sessions, data],
      }));
      setIsAddingNewSession(false);
      setSelectedSession(null);
    }
  };

  return (
    <Layout bg>
      <Modal
        title='Add New Session'
        show={isAddingNewSession}
        setShow={setIsAddingNewSession}
      >
        <div className=''>
          <SingleDropdown
            label='Select Lesson'
            data={
              lessons.length > 0
                ? lessons.map((lesson) => ({
                    id: String(lesson.id),
                    title: lesson.title,
                  }))
                : []
            }
            onChange={(v: string) => {
              setSelectedSession(lessons.find((l) => l.id === +v) || null);
            }}
            value={selectedSession ? String(selectedSession.id) : ''}
          />
          <div className='flex  justify-end items-center mt-4 gap-2'>
            <button
              onClick={() => setIsAddingNewSession(false)}
              className='px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              Cancel
            </button>
            <button
              onClick={() => addNewSessionToCohort()}
              className='px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
      <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
        {/* Main 3 column grid */}
        <div className='grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8'>
          {/* Left column */}
          <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
            {!loading && (
              <ClassDetailPanel
                cohort={cohort}
                workstationAccessCode={wkstCode}
              />
            )}
            <div className='overflow-hidden bg-white rounded-lg shadow'>
              <ul className='divide-y divide-gray-200'>
                {!loading ? (
                  cohort.sessions
                    .sort((a, b) => a.sessionIndex - b.sessionIndex)
                    .map((session, i) => {
                      const notSubmitted = cohort.students.filter(
                        ({ studentId }) =>
                          !session.submissions.find(
                            ({ submitterId }) => studentId === submitterId
                          )
                      );
                      const notGraded = cohort.students.filter(
                        ({ studentId }) =>
                          !notSubmitted.find(
                            (student) => studentId === student.studentId
                          ) &&
                          !session.submissions.find(
                            ({ scoredAt, submitterId }) =>
                              studentId === submitterId && scoredAt
                          )
                      ).length;

                      const showToStudent = !isTeacher
                        ? cohort.locationId !== 'online'
                          ? !!session.attendance.find(
                              (a) => a.studentId === user.value.id
                            )
                          : true
                        : true;
                      if (!showToStudent) return null;

                      const sessionAttendance = session.attendance.map(
                        (a) => a.createdAt
                      );
                      const earliestSessionAttendance = Math.min(
                        ...sessionAttendance.map((date) =>
                          new Date(date).getTime()
                        )
                      );
                      const formatDate =
                        earliestSessionAttendance !== Infinity
                          ? dayjs(earliestSessionAttendance).format(
                              'MM/DD/YYYY dddd'
                            )
                          : 'Not Start Yet';

                      return (
                        <li key={i} className='overflow-hidden bg-white shadow'>
                          <Link
                            to={
                              !isTeacher
                                ? session.attendance.length === 0
                                  ? `/class/${cohort.id}`
                                  : `/class/${cohort.id}/${session.id}`
                                : `/class/${cohort.id}/${session.id}/manage`
                            }
                            className={classNames('block', {
                              ' cursor-not-allowed bg-gray-300':
                                !isTeacher &&
                                (session.disabled ||
                                  !session.attendance.length),

                              ' hover:bg-gray-200':
                                isTeacher ||
                                (!session.disabled &&
                                  session.attendance.length),
                            })}
                          >
                            <div className='flex items-center px-4 py-4 sm:px-6'>
                              <div className='flex-1 min-w-0 sm:flex sm:items-center'>
                                <p className='mr-6 text-5xl font-extrabold italic text-blue-200'>
                                  {i + 1}
                                </p>
                                <div className='truncate'>
                                  <div className='flex text-sm'>
                                    <p className='flex items-center flex-shrink-0 font-medium text-black'>
                                      {session.lesson.title}
                                    </p>
                                    <p className='font-medium truncate text-blue-black'></p>
                                  </div>
                                  {isTeacher && session ? (
                                    <div className='mt-2 text-xs'>
                                      {session.recording ? (
                                        <span className='mr-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                                          Recording{' '}
                                          <CheckCircleIcon className='h-4 pl-1' />
                                        </span>
                                      ) : (
                                        <span className='mr-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                                          Recording{' '}
                                          <XCircleIcon className='h-4 pl-1' />
                                        </span>
                                      )}

                                      {notGraded ? (
                                        <span className='mr-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800'>
                                          Not Graded: {notGraded}
                                        </span>
                                      ) : null}

                                      {session.attendance.length &&
                                      notSubmitted.length ? (
                                        <span className='mr-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                                          Not Submitted: {notSubmitted.length}
                                        </span>
                                      ) : null}
                                      {
                                        <p className='text-gray-500 pt-2'>
                                          {formatDate}
                                        </p>
                                      }
                                    </div>
                                  ) : null}
                                  <div className='flex'>
                                    {cohort.frequency.length &&
                                    !isInfinityClass ? (
                                      <div className='mt-2 flex items-center text-sm text-gray-500'>
                                        <CalendarIcon
                                          className='w-5 h-5 -ml-0.5 text-gray-400'
                                          aria-hidden='true'
                                        />
                                        <p className='ml-1.5'>
                                          {dayjs
                                            .utc(cohort.startDate)
                                            .add(7 * i, 'days')
                                            .format('YYYY/MM/DD')}{' '}
                                          {cohort.frequency[0].startTime} -{' '}
                                          {cohort.frequency[0].endTime} EST (
                                          {dayjs(cohort.startDate).format(
                                            'dddd'
                                          )}
                                          )
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='flex-shrink-0 ml-5'>
                                <ChevronRightIcon
                                  className='w-5 h-5 text-gray-400'
                                  aria-hidden='true'
                                />
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                ) : (
                  <div className='pt-2'>
                    <Loading />
                  </div>
                )}
                {isTeacher && isInfinityClass && (
                  <div
                    onClick={() => setIsAddingNewSession(true)}
                    className='flex justify-center items-center hover:bg-slate-100 hover:cursor-pointer py-6'
                  >
                    <PlusIcon className='h-5 w-5 text-gray-400' />
                    <p className='text-gray-400'>Add New Session</p>
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 lg:col-span-1'>
            <PanelCard divider headerText='Teacher' padding={false}>
              {loading ? (
                <div className='pt-1'>
                  <Loading />
                </div>
              ) : (
                <UserInfoPill
                  avatar={cohort.instructor.profile.avatar || placeholderAvatar}
                  title={cohort.instructor.profile.firstName}
                  customLink={{ component: Link, hrefProperty: 'to' }}
                  redirectTo={`/teacher/${cohort.instructorId}`}
                />
              )}
            </PanelCard>

            <PanelCard divider headerText='Students' padding={false}>
              <div className='bg-white rounded-md'>
                <div className='flow-root'>
                  <ul className='divide-y divide-gray-200'>
                    {loading ? (
                      <div className='pt-1'>
                        <Loading />
                      </div>
                    ) : cohort.students.length ? (
                      cohort.students.map((enrollment, idx) =>
                        enrollment.isActivate ? (
                          <div
                            key={idx}
                            className='items-center flex justify-start'
                          >
                            <UserInfoPill
                              key={enrollment.id}
                              avatar={
                                enrollment.student.profile.avatar ||
                                placeholderAvatar
                              }
                              redirectTo={`/student/${enrollment.studentId}`}
                              statusBadge={
                                isTeacher && !loading
                                  ? cohort.grades[enrollment.studentId] === null
                                    ? 'Not Enough Data'
                                    : cohort.grades[
                                        enrollment.studentId
                                      ].toLocaleString('en-US', {
                                        maximumFractionDigits: 2,
                                      }) + '%'
                                  : null
                              }
                              statusType={
                                !cohort.grades[enrollment.studentId]
                                  ? 'plain'
                                  : cohort.grades[enrollment.studentId] >= 85
                                  ? 'success'
                                  : cohort.grades[enrollment.studentId] < 85 &&
                                    cohort.grades[enrollment.studentId] > 70
                                  ? 'warning'
                                  : 'danger'
                              }
                              title={`${enrollment.student.profile.firstName} ${enrollment.student.profile.lastName}`}
                            />
                            {isTeacher && !loading ? (
                              <div className='flex flex-col justify-between -translate-x-4'>
                                <div>&nbsp;</div>
                                <p className='text-sm bg-gray-100 rounded-full px-2'>
                                  {enrollment.studentId}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )
                    ) : (
                      <Empty />
                    )}
                  </ul>
                </div>
              </div>
            </PanelCard>

            {!isTeacher ? (
              <PanelCard divider headerText='Evaluations' padding={false}>
                <div className='bg-white rounded-md'>
                  <div className='flow-root'>
                    <ul className='divide-y divide-gray-200'>
                      {loading ? (
                        <Loading />
                      ) : (
                        <>
                          {evaluationIntervalStartIndex.map(
                            (startSession, index) => {
                              const allowEvaluate =
                                completedSessions.length >= startSession + 5;

                              const evaluated = evaluatedSessions[startSession];

                              return (
                                <li
                                  key={index}
                                  className={`flex justify-between h-16 items-center px-6 text-sm font-medium ${
                                    evaluated ? 'bg-green-100' : ''
                                  }`}
                                >
                                  Sessions: {startSession + 1} -{' '}
                                  {startSession + EVALUATION_SESSION_INTERVAL}
                                  {allowEvaluate && !evaluated ? (
                                    <Link
                                      to={`/evaluation/${cohort.sessions[startSession].id}/${studentId}/${teacherId}/student`}
                                      className='font-normal text-blue-500 hover:underline'
                                    >
                                      Evaluate
                                    </Link>
                                  ) : (
                                    <span
                                      className={`font-normal text-gray-400 ${
                                        evaluated ? 'cursor-not-allowed' : ''
                                      }`}
                                    >
                                      {evaluated
                                        ? 'Evaluated'
                                        : !allowEvaluate
                                        ? 'Not Ready'
                                        : 'Evaluate'}
                                    </span>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </PanelCard>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClassDetailPage;
